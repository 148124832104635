<template>
	<div class="px-0" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<!-- <pre>{{ newTicket }}</pre> -->
		<span v-if="analyzingImage">
			<loading :active.sync="analyzingImage" loader="bars" is-full-page />
		</span>
		<b-alert v-if="!analyzingImage && isNoAnalyzeImgResult" show dismissible fade>
			{{ FormMSG(9875678, 'No billing information detected in this image') }}
		</b-alert>
		<b-form-group :label="rendReferenceLabel" label-for="new-expense_ticket-ref">
			<b-form-input id="new-expense_ticket-ref" :value="currNewTicketRef" disabled />
		</b-form-group>
		<b-form-group v-if="$props.useComponent" :label="FormMSG(70, 'Select Person')" label-for="new-expense_ticket-ref">
			<b-form-select
				size="md"
				v-model="user.value"
				value-field="value"
				text-field="name"
				disabled-field="notEnabled"
				:options="users"
				:disabled="disableSelectUser"
				@change="handleSelectUser"
			/>
		</b-form-group>
		<CapturesPackage
			multiple
			preview-mode="button"
			parent-type="expense_item"
			parent-sub-type="ticket"
			:parent-id.sync="currNewTicketRef"
			:pictures.sync="rendImagesListForPreviewer"
			:add-image-disabled.sync="analyzingImage"
			:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
			@change="handleImgsTaken"
			@upload-image:loading="handleUploadImgStatus"
		/>
		<DateDescription :edit-data="expense" @change="handleDateAndDescriptionChanges" class="pt-3" />
		<b-form-group :label="FormMSG(9989898, 'Description')" label-for="new-expense_description">
			<b-form-textarea
				id="new-expense_description"
				v-model="newTicket.description"
				rows="3"
				class="mt-0"
				:class="{
					'is-invalid': $v.newTicket.description.$error
				}"
			/>
			<div v-if="$v.newTicket.description.$error" class="invalid-feedback">
				{{ FormMSG(76, 'Please, reference is required') }}
			</div>
		</b-form-group>
		<SupplierSelector
			:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
			:label="FormMSG(4, 'Name of a supplier')"
			:placeholder="FormMSG(5445, 'Filter by name')"
			:addlabel="FormMSG(5446, 'Add')"
			:update-label="FormMSG(9, 'Update')"
			:supplier-id="supplierId"
			:ocr-suppliers="suppliersInImages"
			@supplier:unselected="onUnselected"
			@change="handleSupplierChange"
		/>
		<div class="mt-3">
			<department-selection
				:edit-data="expense"
				:form-type="formType"
				:is-new="isNew"
				:is-submitted="isSubmitted"
				@change="handleDepartmentAndCatChange"
				@department:selector:invalid="isInvalidateFields"
			/>
		</div>
		<b-form-group :label="FormMSG(5, 'Type of paiement')" label-for="new-expense_ticket-paiement-type">
			<b-form-select id="new-expense_ticket-paiement-type" v-model="newTicket.paiementType" :options="mapPaiementTypes" @change="setPaiementTypeName" />
		</b-form-group>
		<b-row>
			<b-col>
				<b-form-group :label="FormMSG(6, 'Total amount')" label-for="new-expense_amount-total">
					<b-form-input
						id="new-expense_amount-total"
						:class="isInvalid || ($v.newTicket && $v.newTicket.amountTotal && $v.newTicket.amountTotal.$error) ? 'is-invalid' : ''"
						v-model="newTicket.amountTotal"
						type="number"
						placeholder="0.00"
						step="0.01"
						@input="handleAmountTotalChange"
					/>
					<small v-if="isInvalid || ($v.newTicket && $v.newTicket.amountTotal && $v.newTicket.amountTotal.$error)" style="color: red">{{
						isInvalid
							? FormMSG(25011, 'Must be greater than VAT amount')
							: $v.newTicket && $v.newTicket.amountTotal.$error
							? FormMSG(73, 'Please, value must be greater than 0')
							: ''
					}}</small>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group :label="FormMSG(74, 'VAT')" label-for="type-of-vat">
					<v-select
						v-model="vatType"
						label="text"
						:options="taxOptions"
						:reduce="(option) => option.value"
						:get-option-label="(option) => (option.item ? option.item.factor : '')"
						:placeholder="FormMSG(75, 'Select a VAT')"
						:selectable="selectableItem"
						@input="handleVatChange"
					>
						<template #option="option">
							<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
							<div class="text-color ml-3" v-else>{{ option.text }} - {{ option.item.name }} ({{ option.item.factor }}%)</div>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group :label="FormMSG(7, 'VAT amount')" label-for="new-expense_amout-vat">
					<b-form-input
						:class="isInvalid ? 'is-invalid' : ''"
						id="new-expense_amout-vat"
						:max="newTicket.amountTotal"
						v-model="newTicket.amountVat"
						type="number"
						placeholder="0.00"
						step="0.01"
						:disabled="vatAmountDisabled"
					/>
					<small v-if="isInvalid" style="color: red">{{ FormMSG(25021, 'Must be smaller than total amount') }}</small>
				</b-form-group>
			</b-col>
		</b-row>
		<!-- <Capture v-if="!$isPwa()" v-model="isCaptureModalOpen" multiple-img dynamic-crop-size :edit-data="expense" @change="handleImgsTaked" /> -->
		<b-modal
			id="ocr-issue-modal"
			header-class="header-class-modal-doc-package"
			:title="FormMSG(78, 'Error')"
			class="modal-success"
			v-model="ocrIssueModal"
			@ok="ocrIssueModal = false"
			header-bg-variant="danger"
			header-border-variant="danger"
			ok-variant="light"
			ok-only
		>
			{{ FormMSG(77, 'An error has occurred during created the expense, please retry again or contact your administrator.') }}
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { isNil, objReducer, greaterThanZero } from '@/shared/utils';
import mapProps from '@/shared/vuePropsMapper';
import getVatData from '@/shared/vat-helper';
import languageMessages from '@/mixins/languageMessages';
import DateDescription from '@/components/ExpenseService/DateSelection';
// import Capture from '@/components/ExpenseService/Capture';
// import GetPictureFromCameraPwaBtn from '@/components/Packages/Captures/components/GetPictureFromCameraPwaBtn'
import SupplierSelector from '@/components/SupplierSelector';
import ocrImageAnalysis from '../../services/ocr.service';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { getFileExtension } from '@/shared/helpers';
import { getUsers } from '@/cruds/users.crud';
import { expenseItemsFromImageOrPdf } from '@/cruds/expense.crud';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import moment from 'moment';
import _ from 'lodash';
import { VALIDATION_TYPE } from '@/shared/constants';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';

const defaultTicket = {
	supplierName: '',
	department: null,
	category: null,
	amountTotal: 0,
	amountVat: 0,
	paiementType: 0,
	paiementTypeName: 'Cash',
	description: '',
	images: [],
	flagItemId: 0,
	projectFlagItems: null
};

function extractRegexByLabel(ocrResults, label) {
	if (!ocrResults) {
		return null;
	}
	const { regexMatches } = ocrResults;
	const matches = regexMatches.filter((m) => m.label === label);
	const uniques = Array.from(new Set(matches && matches[0].match.map((ma) => ma[0])));
	return uniques;
}

function extractVATNumbers(ocrResults) {
	return extractRegexByLabel(ocrResults, 'VAT');
}

function extractEmails(ocrResults) {
	return extractRegexByLabel(ocrResults, 'EMAIL');
}

export default {
	name: 'CreateNewExpenseTicketForm',
	components: {
		DateDescription,
		Loading,
		// GetPictureFromCameraPwaBtn,
		CapturesPackage,
		departmentSelection: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		// Capture,
		SupplierSelector,
		CoTwoClap
	},
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		...mapProps(['formType', 'expenseType'], {
			type: Number,
			required: false,
			default: 0 // - ticket: 0, invoice: 1
		}),
		userValue: {
			type: Object,
			required: false
		},
		useComponent: {
			type: Boolean,
			default: false,
			required: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		isSubmitted: {
			type: Boolean,
			required: true,
			default: false
		},
		isNew: {
			type: Boolean
		}
	},
	data() {
		return {
			isCaptureModalOpen: false,
			show: true,
			newTicket: Object.assign({}, defaultTicket),
			analyzingImage: false,
			suppliersInImages: [],
			isNoAnalyzeImgResult: false,
			isSupplierUpdate: false,
			uploadImgStatus: false,
			user: {
				name: '',
				value: 0
			},
			disableSelectUser: false,
			users: [{ value: 0, name: 'Select ...' }],
			allowExpenseEntryOutsideContract: 0,
			messageAllowExpenseEntryOutsideContract: '',
			dateSelected: moment(Date.now()).format('YYYY-MM-DD') + 'T00:00:00Z',
			showPopUp: false,
			showAlert: false,
			parentIdCoTwoClap: 0,
			forCreateCoTwoClap: true,
			submitCarbon: false,
			vatType: null,
			originalVatAmount: 0,
			vatAmountDisabled: false,
			vatValueChoosed: null,
			expense: null,
			validationType: 0,
			ocrIssueModal: false
		};
	},
	computed: {
		isInvalid() {
			if (parseFloat(this.newTicket.amountTotal) > 0) {
				return parseFloat(this.newTicket.amountTotal) < parseFloat(this.newTicket.amountVat);
			}
			return false;
		},
		...mapGetters({
			currNewTicketRef: 'expenses/currTicketRef',
			paiementTypes: 'expenses/paiementTypes',
			selectedPO: 'purchaces/purchaseOrder',
			taxOptions: 'flag/taxOptions'
		}),
		/**
		 * @return {String}
		 */
		rendReferenceLabel() {
			const _C = this.isInvoiceTicket;
			const _t = this.FormMSG;

			const t = {
				i: _t(1, 'Invoice'),
				t: _t(2, 'Ticket')
			};
			return [t[_C ? 'i' : 't'], _t(3, 'reference')].join(' ');
		},
		mapPaiementTypes() {
			return this.paiementTypes.map(({ id, name }) => {
				return {
					value: id,
					text: this.FormMSG(555555 + id, name)
				};
			});
		},
		isInvoiceTicket: {
			get() {
				return this.expenseType === 1;
			},
			set(val) {
				return val;
			}
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.expense) || parseInt(this.expense.supplierId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.expense.supplierId;
		},
		/**
		 * @return {Array}
		 */
		rendImagesListForPreviewer() {
			if (this.newTicket.images.length === 0) return [];
			return this.newTicket.images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));
		},
		setAllowExpenseEntryOutsideContract() {
			return store.state.myProject.allowExpenseEntryOutsideContract;
		}
	},
	watch: {
		editData: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.expense = newVal;
					this.parentIdCoTwoClap = +newVal.id;
					this.forCreateCoTwoClap = false;
					this.validationType = this.expense.validated;
				} else {
					this.forCreateCoTwoClap = true;
				}
			},
			immediate: true,
			deep: true
		},
		isSubmitted: {
			handler(newVal) {
				// console.log({ isSubmitted: newVal });
			},
			immediate: true,
			deep: true
		},
		/**
		 * @param {Object} ticket
		 */
		userValue: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		newTicket: {
			handler(ticket) {
				this.emitChange(ticket);
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.$nextTick(async () => {
			this.initEditMode();
			this.initVat();
			this.handleSelectUser(this.user.value);
			this.setUser();
			this.getAllUsers();
			await this.getTaxOptions();
		});
	},
	mounted() {
		this.$v.$touch();
		this.$bus.$on('filepreviewer:image-deleted-from-parent', (payload) => {
			if (isNil(this.newTicket)) return;
			this.newTicket.images = isNil(payload.refreshedImages) ? [] : payload.refreshedImages;
		});
	},
	methods: {
		...mapActions({
			getTaxOptions: 'flag/getTaxOptions'
		}),
		handleCoTwoClapEndSubmit() {},
		handleSelectUser(value) {
			this.$emit('changeUser', value);
			return;
		},
		setUser() {
			if (this.expense && this.useComponent === true) {
				this.disableSelectUser = true;
				this.user.value = this.expense.user.id;
				this.$emit('changeUser', this.user.value);
				return;
			}
			if (!this.expense && this.useComponent === true) {
				if (this.userValue.value !== undefined) {
					this.user.value = this.userValue.value;
					this.$emit('changeUser', this.user.value);
					return;
				} else {
					this.user.value = store.state.myProfile.id;
					this.$emit('changeUser', this.user.value);
					return;
				}
			}
			if (this.expense) {
				this.user.value = +this.expense.user.id;
				this.$emit('changeUser', this.user.value);
				return;
			} else {
				this.user.value = store.state.myProfile.id;
				this.$emit('changeUser', this.user.value);
				return;
			}
		},
		async getAllUsers() {
			let forTimeSheet = true;
			this.users = [
				...this.users,
				...[...(await getUsers({ myProject: true }, 0, -1, 0, 0, 0, '', '', forTimeSheet))].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];
			if (this.useComponent) {
				this.users = this.users.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			let isResult = this.users.find((item) => +item.value === +this.user.value);
			if (isResult === undefined) {
				this.user.value = 0;
				this.$emit('changeUser', this.user.value);
			} else {
				this.$emit('changeUser', this.user.value);
			}
		},
		initEditMode() {
			if (isNil(this.expense)) return;
			const omitFields = Object.keys(defaultTicket);
			const obj = objReducer(omitFields, this.expense);
			this.setTicketData(obj);
		},
		/**
		 * @param {Array} images
		 */
		async handleImgsTaken(images) {
			this.analyzingImage = true;
			this.newTicket.images = images;

			if (
				+this.validationType === VALIDATION_TYPE.NOT_SUBMITTED ||
				+this.validationType === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
				+this.validationType === VALIDATION_TYPE.REFUSED_LEVEL_2
			) {
				/**
				 * remove ext from xid files..
				 * @type {Array} cleanImgs
				 */
				const cleanImgs = images.map((_) => _.replace(/\.[^\.]*$/, ''));
				const [newExpense] = await expenseItemsFromImageOrPdf(cleanImgs).catch((error) => {
					console.error({ error });
					this.newTicket.images.pop();
					this.ocrIssueModal = true;
					this.analyzingImage = false;
				});
				// await this.setOcrAnalyses(images);

				this.newTicket = { ...this.newTicket, ...newExpense };
				this.expense = { ...this.expense, ...this.newTicket };
				this.$emit('payload-refreshed', this.newTicket);
			}

			this.analyzingImage = false;
		},
		/**
		 * Merge some data to newticket element
		 * @param {Object} obj
		 */
		setTicketData(obj) {
			this.newTicket = Object.assign({}, defaultTicket, this.newTicket, obj);
			if (!isNil(this.expense) && !isNil(this.expense.user)) {
				this.user.value = +this.expense.user.id;
			}
		},
		emitChange(ticket) {
			this.$emit(
				'change',
				{
					...ticket,
					amountTotal: parseFloat(ticket.amountTotal),
					amountVat: parseFloat(ticket.amountVat)
				},
				this.$v.$invalid
			);
		},
		handleDepartmentAndCatChange(element) {
			this.setTicketData(element);
		},
		handleDateAndDescriptionChanges(obj) {
			const _o = objReducer(['date'], obj);
			this.dateSelected = _o.date;
			this.setTicketData(_o);
		},
		/**
		 * @param {Object} supplier
		 */
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;

			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, supplierName } = supplier;
			this.setTicketData({
				supplierId: parseInt(id),
				supplierName: supplierName
			});
		},
		handleUploadImgStatus(status) {
			this.$emit('upload-image-loading:status', status);
		},
		/**
		 * @async
		 * @method
		 * @param {!Array} imagesData
		 * @return {Void}
		 */
		async setOcrAnalyses(imagesData) {
			this.analyzingImage = true;
			try {
				const ocrResults = await ocrImageAnalysis(imagesData);
				const vatNumbers = extractVATNumbers(ocrResults);
				const emails = extractEmails(ocrResults);
				const trimmedVatNumbers = vatNumbers.map((vatNumber) => vatNumber.replaceAll('.', '').replaceAll(' ', ''));

				const validVatNumbers = [];
				for (const vat of trimmedVatNumbers) {
					const res = await getVatData(vat);
					if (res.valid === 'true') {
						res.vat = vat;
						validVatNumbers.push(res);
					}
				}

				this.suppliersInImages = [].concat(
					validVatNumbers.map((vat) => ({ label: 'VAT', value: vat })),
					emails.map((email) => ({ label: 'EMAIL', value: email }))
				);
			} catch (e) {
				this.isNoAnalyzeImgResult = true;
			}

			this.analyzingImage = false;
		},
		setPaiementTypeName(val) {
			if (isNil(val)) return;
			this.paiementTypeName = this.paiementTypes[val].name;
		},
		isInvalidateFields(payload) {
			this.$emit('ticket:invalid', payload);
		},
		onUnselected() {
			this.isSupplierUpdate = false;
		},
		selectableItem(item) {
			return !item.disabled;
		},
		handleVatChange(payload) {
			this.vatValueChoosed = payload;

			if (!_.isNil(payload)) {
				// console.log({ payload, taxOptions: this.taxOptions.filter((tax) => +payload === +tax.item.id && !tax.disabled)[0].item.factor });
				this.calculateVatAmount(payload);
				this.vatAmountDisabled = true;
				this.newTicket.flagItemId = +payload;
			} else {
				this.newTicket.amountVat = this.originalVatAmount;
				this.vatAmountDisabled = false;
				this.newTicket.flagItemId = 0;
			}

			this.$v.$touch();
		},
		initVat() {
			this.originalVatAmount = !_.isNil(this.expense) ? this.expense.amountVat : 0;
			this.newTicket.flagItemId = 0;

			if (
				!_.isUndefined(this.newTicket.projectFlagItems) &&
				!_.isNil(this.newTicket.projectFlagItems) &&
				this.newTicket.projectFlagItems.taxeFlags.length > 0
			) {
				this.vatType = this.newTicket.flagItemId = this.newTicket.projectFlagItems.taxeFlags[0].id;
				this.vatAmountDisabled = true;
			}
		},
		handleAmountTotalChange(payload) {
			if (!_.isNil(this.vatType)) {
				this.calculateVatAmount(this.vatType);
			}
		},
		calculateVatAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;

			let amount = this.newTicket.amountTotal / ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));
			this.newTicket.amount = amount;

			let amountVat = parseFloat((this.newTicket.amountTotal - amount).toFixed(2));
			this.newTicket.amountVat = amountVat;
		}
	},
	validations() {
		if (!_.isNil(this.vatValueChoosed)) {
			return {
				newTicket: {
					amountTotal: {
						required,
						decimal
					},
					description: {
						required,
						min: minLength(4)
					}
				}
			};
		}

		return {
			newTicket: {
				description: {
					required,
					min: minLength(4)
				}
			}
		};
	}
};
</script>
<style lang="scss">
.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

#ocr-issue-modal .header-class-modal-doc-package h5 {
	color: #fff;
}
</style>
