var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-0",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _vm.analyzingImage
        ? _c(
            "span",
            [
              _c("loading", {
                attrs: {
                  active: _vm.analyzingImage,
                  loader: "bars",
                  "is-full-page": "",
                },
                on: {
                  "update:active": function ($event) {
                    _vm.analyzingImage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.analyzingImage && _vm.isNoAnalyzeImgResult
        ? _c("b-alert", { attrs: { show: "", dismissible: "", fade: "" } }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    9875678,
                    "No billing information detected in this image"
                  )
                ) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.rendReferenceLabel,
            "label-for": "new-expense_ticket-ref",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "new-expense_ticket-ref",
              value: _vm.currNewTicketRef,
              disabled: "",
            },
          }),
        ],
        1
      ),
      _vm.$props.useComponent
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(70, "Select Person"),
                "label-for": "new-expense_ticket-ref",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  size: "md",
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                  options: _vm.users,
                  disabled: _vm.disableSelectUser,
                },
                on: { change: _vm.handleSelectUser },
                model: {
                  value: _vm.user.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "value", $$v)
                  },
                  expression: "user.value",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("CapturesPackage", {
        attrs: {
          multiple: "",
          "preview-mode": "button",
          "parent-type": "expense_item",
          "parent-sub-type": "ticket",
          "parent-id": _vm.currNewTicketRef,
          pictures: _vm.rendImagesListForPreviewer,
          "add-image-disabled": _vm.analyzingImage,
          "preview-modal-options": {
            zIndex: 1041,
            hideLikeDislike: true,
            hideCommentButton: true,
            hideDisLikeButton: true,
          },
        },
        on: {
          "update:parentId": function ($event) {
            _vm.currNewTicketRef = $event
          },
          "update:parent-id": function ($event) {
            _vm.currNewTicketRef = $event
          },
          "update:pictures": function ($event) {
            _vm.rendImagesListForPreviewer = $event
          },
          "update:addImageDisabled": function ($event) {
            _vm.analyzingImage = $event
          },
          "update:add-image-disabled": function ($event) {
            _vm.analyzingImage = $event
          },
          change: _vm.handleImgsTaken,
          "upload-image:loading": _vm.handleUploadImgStatus,
        },
      }),
      _c("DateDescription", {
        staticClass: "pt-3",
        attrs: { "edit-data": _vm.expense },
        on: { change: _vm.handleDateAndDescriptionChanges },
      }),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(9989898, "Description"),
            "label-for": "new-expense_description",
          },
        },
        [
          _c("b-form-textarea", {
            staticClass: "mt-0",
            class: {
              "is-invalid": _vm.$v.newTicket.description.$error,
            },
            attrs: { id: "new-expense_description", rows: "3" },
            model: {
              value: _vm.newTicket.description,
              callback: function ($$v) {
                _vm.$set(_vm.newTicket, "description", $$v)
              },
              expression: "newTicket.description",
            },
          }),
          _vm.$v.newTicket.description.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(76, "Please, reference is required")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("SupplierSelector", {
        attrs: {
          title: `${
            !_vm.isSupplierUpdate
              ? _vm.FormMSG(250, "Add supplier details")
              : _vm.FormMSG(251, "Update supplier details")
          }`,
          label: _vm.FormMSG(4, "Name of a supplier"),
          placeholder: _vm.FormMSG(5445, "Filter by name"),
          addlabel: _vm.FormMSG(5446, "Add"),
          "update-label": _vm.FormMSG(9, "Update"),
          "supplier-id": _vm.supplierId,
          "ocr-suppliers": _vm.suppliersInImages,
        },
        on: {
          "supplier:unselected": _vm.onUnselected,
          change: _vm.handleSupplierChange,
        },
      }),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("department-selection", {
            attrs: {
              "edit-data": _vm.expense,
              "form-type": _vm.formType,
              "is-new": _vm.isNew,
              "is-submitted": _vm.isSubmitted,
            },
            on: {
              change: _vm.handleDepartmentAndCatChange,
              "department:selector:invalid": _vm.isInvalidateFields,
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(5, "Type of paiement"),
            "label-for": "new-expense_ticket-paiement-type",
          },
        },
        [
          _c("b-form-select", {
            attrs: {
              id: "new-expense_ticket-paiement-type",
              options: _vm.mapPaiementTypes,
            },
            on: { change: _vm.setPaiementTypeName },
            model: {
              value: _vm.newTicket.paiementType,
              callback: function ($$v) {
                _vm.$set(_vm.newTicket, "paiementType", $$v)
              },
              expression: "newTicket.paiementType",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(6, "Total amount"),
                    "label-for": "new-expense_amount-total",
                  },
                },
                [
                  _c("b-form-input", {
                    class:
                      _vm.isInvalid ||
                      (_vm.$v.newTicket &&
                        _vm.$v.newTicket.amountTotal &&
                        _vm.$v.newTicket.amountTotal.$error)
                        ? "is-invalid"
                        : "",
                    attrs: {
                      id: "new-expense_amount-total",
                      type: "number",
                      placeholder: "0.00",
                      step: "0.01",
                    },
                    on: { input: _vm.handleAmountTotalChange },
                    model: {
                      value: _vm.newTicket.amountTotal,
                      callback: function ($$v) {
                        _vm.$set(_vm.newTicket, "amountTotal", $$v)
                      },
                      expression: "newTicket.amountTotal",
                    },
                  }),
                  _vm.isInvalid ||
                  (_vm.$v.newTicket &&
                    _vm.$v.newTicket.amountTotal &&
                    _vm.$v.newTicket.amountTotal.$error)
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.isInvalid
                              ? _vm.FormMSG(
                                  25011,
                                  "Must be greater than VAT amount"
                                )
                              : _vm.$v.newTicket &&
                                _vm.$v.newTicket.amountTotal.$error
                              ? _vm.FormMSG(
                                  73,
                                  "Please, value must be greater than 0"
                                )
                              : ""
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(74, "VAT"),
                    "label-for": "type-of-vat",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      label: "text",
                      options: _vm.taxOptions,
                      reduce: (option) => option.value,
                      "get-option-label": (option) =>
                        option.item ? option.item.factor : "",
                      placeholder: _vm.FormMSG(75, "Select a VAT"),
                      selectable: _vm.selectableItem,
                    },
                    on: { input: _vm.handleVatChange },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            option.disabled
                              ? _c("div", { staticClass: "text-bold" }, [
                                  _vm._v(_vm._s(option.text)),
                                ])
                              : _c("div", { staticClass: "text-color ml-3" }, [
                                  _vm._v(
                                    _vm._s(option.text) +
                                      " - " +
                                      _vm._s(option.item.name) +
                                      " (" +
                                      _vm._s(option.item.factor) +
                                      "%)"
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.vatType,
                      callback: function ($$v) {
                        _vm.vatType = $$v
                      },
                      expression: "vatType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(7, "VAT amount"),
                    "label-for": "new-expense_amout-vat",
                  },
                },
                [
                  _c("b-form-input", {
                    class: _vm.isInvalid ? "is-invalid" : "",
                    attrs: {
                      id: "new-expense_amout-vat",
                      max: _vm.newTicket.amountTotal,
                      type: "number",
                      placeholder: "0.00",
                      step: "0.01",
                      disabled: _vm.vatAmountDisabled,
                    },
                    model: {
                      value: _vm.newTicket.amountVat,
                      callback: function ($$v) {
                        _vm.$set(_vm.newTicket, "amountVat", $$v)
                      },
                      expression: "newTicket.amountVat",
                    },
                  }),
                  _vm.isInvalid
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              25021,
                              "Must be smaller than total amount"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            id: "ocr-issue-modal",
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(78, "Error"),
            "header-bg-variant": "danger",
            "header-border-variant": "danger",
            "ok-variant": "light",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.ocrIssueModal = false
            },
          },
          model: {
            value: _vm.ocrIssueModal,
            callback: function ($$v) {
              _vm.ocrIssueModal = $$v
            },
            expression: "ocrIssueModal",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(
                  77,
                  "An error has occurred during created the expense, please retry again or contact your administrator."
                )
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }