var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-0",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      this.$props.useComponent
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(76, "Select Person"),
                "label-for": "new-expense_ticket-ref",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  size: "md",
                  options: _vm.users,
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                  disabled: _vm.disableSelectUser,
                },
                on: { change: _vm.handleSelectUser },
                model: {
                  value: _vm.user.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "value", $$v)
                  },
                  expression: "user.value",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("DateDescription", {
        attrs: { "edit-data": _vm.editData },
        on: { change: _vm.handleDateAndDescriptionChanges },
      }),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(9989898, "Description"),
            "label-for": "new-expense_description",
          },
        },
        [
          _c("b-form-textarea", {
            staticClass: "mt-0",
            class: {
              "is-invalid": _vm.$v.mileage.description.$error,
            },
            attrs: { id: "new-expense_description", rows: "3" },
            model: {
              value: _vm.mileage.description,
              callback: function ($$v) {
                _vm.$set(_vm.mileage, "description", $$v)
              },
              expression: "mileage.description",
            },
          }),
          _vm.$v.mileage.description.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(43, "Please, reference is required")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("transport-type-selector", {
                ref: "transportTypeRef",
                attrs: {
                  "data-additional-detail": _vm.mileage.additionalSubType,
                  "data-detail": _vm.mileage.subType,
                  "data-type": _vm.mileage.subCategory,
                  "hide-boat-type": "",
                  "hide-freight-type": "",
                  "hide-menues-not-two-wheels": "",
                },
                on: {
                  "transportation-type-selector:change":
                    _vm.handleTransportationTypeSelectorChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("GoogleDistance", {
        attrs: {
          "edit-data": _vm.editData,
          "to-location-label": _vm.FormMSG(2, "To location"),
          "from-location-label": _vm.FormMSG(1, "From location"),
        },
        on: { change: _vm.handleDistanceChanges },
      }),
      _c("department-selection", {
        attrs: {
          "edit-data": _vm.editData,
          "form-type": _vm.formType,
          "is-new": _vm.isNew,
          "hide-transportation-mean": "",
          "category-for-transportation": "",
          "is-submitted": _vm.isSubmitted,
        },
        on: {
          change: _vm.handleDepartmentAndCatChange,
          "department:selector:invalid": _vm.isInvalidateFields,
        },
      }),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(3, "Travel distance"),
            "label-for": "new-expense_travel-distance",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.$v.mileage.km.$error,
                        },
                        attrs: {
                          id: "new-expense_travel-distance",
                          type: "number",
                          min: "0",
                          step: "0.5",
                          placeholder: "0",
                        },
                        on: { change: _vm.handleCategoryChange },
                        model: {
                          value: _vm.mileage.km,
                          callback: function ($$v) {
                            _vm.$set(_vm.mileage, "km", $$v)
                          },
                          expression: "mileage.km",
                        },
                      }),
                      _vm.$v.mileage.km.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    44,
                                    "Please, value must be greater than 0"
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info-message",
                      attrs: { id: "tooltip-distance" },
                      on: { click: _vm.openInfoDistance },
                    },
                    [
                      _c("span", [_c("info", { attrs: { size: 16 } })], 1),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.FormMSG(7, "Info message"))),
                      ]),
                    ]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "tooltip-distance",
                        show: _vm.popInfoDistance,
                        placement: "bottom",
                      },
                      on: {
                        "update:show": function ($event) {
                          _vm.popInfoDistance = $event
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              8,
                              "You can always change this unit in your project configuration"
                            )
                          ) +
                          " (" +
                          _vm._s(_vm.distanceUnit) +
                          ")\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex justify-content-start align-items-center px-0",
                  staticStyle: { height: "calc(1.5em + 0.75rem + 2px)" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { color: "#06263e", "font-size": "1.25rem" },
                    },
                    [_vm._v(_vm._s(_vm.distanceUnit))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-form-group",
        [
          _c("label", [
            _vm._v(_vm._s(this.FormMSG(5, "Your green impact")) + " (Kg CO"),
            _c("sub", [_vm._v("2")]),
            _vm._v(")"),
          ]),
          _c("b-form-input", {
            attrs: {
              id: "new-expense_travel-kgCoTwo",
              type: "number",
              placeholder: "0",
              disabled: "",
            },
            model: {
              value: _vm.mileage.kgCoTwo,
              callback: function ($$v) {
                _vm.$set(_vm.mileage, "kgCoTwo", $$v)
              },
              expression: "mileage.kgCoTwo",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }