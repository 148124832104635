var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new_expense_date_wrapper" }, [
    _c(
      "div",
      { staticClass: "bootstrap-date-selection" },
      [
        _c("label", { attrs: { for: "new-expense_amount-date" } }, [
          _vm._v(_vm._s(_vm.FormMSG(1, "Choose a date"))),
        ]),
        _c("b-form-datepicker", {
          attrs: { id: "new-expense_amount-date", locale: _vm.currentLang },
          model: {
            value: _vm.element.date,
            callback: function ($$v) {
              _vm.$set(_vm.element, "date", $$v)
            },
            expression: "element.date",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }