<template>
	<div class="px-0" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<!-- <pre>{{ mileage }}</pre> -->
		<b-form-group v-if="this.$props.useComponent" :label="FormMSG(76, 'Select Person')" label-for="new-expense_ticket-ref">
			<b-form-select
				@change="handleSelectUser"
				size="md"
				v-model="user.value"
				:options="users"
				value-field="value"
				text-field="name"
				disabled-field="notEnabled"
				:disabled="disableSelectUser"
			></b-form-select>
		</b-form-group>
		<DateDescription :edit-data="editData" @change="handleDateAndDescriptionChanges" />
		<b-form-group :label="FormMSG(9989898, 'Description')" label-for="new-expense_description">
			<b-form-textarea
				id="new-expense_description"
				v-model="mileage.description"
				rows="3"
				class="mt-0"
				:class="{
					'is-invalid': $v.mileage.description.$error
				}"
			/>
			<div v-if="$v.mileage.description.$error" class="invalid-feedback">
				{{ FormMSG(43, 'Please, reference is required') }}
			</div>
		</b-form-group>
		<b-row>
			<b-col cols="12">
				<transport-type-selector
					ref="transportTypeRef"
					:data-additional-detail="mileage.additionalSubType"
					:data-detail="mileage.subType"
					:data-type="mileage.subCategory"
					hide-boat-type
					hide-freight-type
					hide-menues-not-two-wheels
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
		</b-row>
		<!--		<b-row>-->
		<!--			<b-col cols="12">-->
		<!--				<b-form-group :label="FormMSG(4, 'Transportation mean')" label-for="optionsForTransportMean">-->
		<!--					<b-form-select-->
		<!--						id="optionsForTransportMean"-->
		<!--						v-model="mileage.subCategory"-->
		<!--						:options="optionsForTransportMean"-->
		<!--						@change="handleCategoryChange"-->
		<!--					/>-->
		<!--				</b-form-group>-->
		<!--			</b-col>-->
		<!--		</b-row>-->
		<GoogleDistance
			:edit-data="editData"
			:to-location-label="FormMSG(2, 'To location')"
			:from-location-label="FormMSG(1, 'From location')"
			@change="handleDistanceChanges"
		/>
		<!-- ADD DEPARTMENT FOR CO2 CATEGORY -->
		<department-selection
			:edit-data="editData"
			:form-type="formType"
			:is-new="isNew"
			hide-transportation-mean
			category-for-transportation
			:is-submitted="isSubmitted"
			@change="handleDepartmentAndCatChange"
			@department:selector:invalid="isInvalidateFields"
		/>
		<b-form-group :label="FormMSG(3, 'Travel distance')" label-for="new-expense_travel-distance">
			<b-row>
				<b-col cols="9">
					<b-input-group>
						<b-form-input
							id="new-expense_travel-distance"
							type="number"
							min="0"
							step="0.5"
							v-model="mileage.km"
							placeholder="0"
							@change="handleCategoryChange"
							:class="{
								'is-invalid': $v.mileage.km.$error
							}"
						/>
						<div v-if="$v.mileage.km.$error" class="invalid-feedback">
							{{ FormMSG(44, 'Please, value must be greater than 0') }}
						</div>
					</b-input-group>
					<div class="info-message" id="tooltip-distance" @click="openInfoDistance">
						<span><info :size="16" /></span>
						<span class="label">{{ FormMSG(7, 'Info message') }}</span>
					</div>
					<b-popover target="tooltip-distance" :show.sync="popInfoDistance" placement="bottom">
						{{ FormMSG(8, 'You can always change this unit in your project configuration') }} ({{ distanceUnit }})
					</b-popover>
				</b-col>
				<div class="col d-flex justify-content-start align-items-center px-0" style="height: calc(1.5em + 0.75rem + 2px)">
					<span class="font-weight-bold" style="color: #06263e; font-size: 1.25rem">{{ distanceUnit }}</span>
				</div>
			</b-row>
		</b-form-group>
		<b-form-group>
			<label>{{ this.FormMSG(5, 'Your green impact') }} (Kg CO<sub>2</sub>)</label>
			<b-form-input id="new-expense_travel-kgCoTwo" type="number" v-model="mileage.kgCoTwo" placeholder="0" disabled />
		</b-form-group>
	</div>
</template>

<script>
import { objReducer, isNil, greaterThanZero } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import DateDescription from '@/components/ExpenseService/DateSelection';
import GoogleDistance from '@/components/GoogleDistance';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import { Info } from 'lucide-vue';
import { store } from '@/store';
import { recalculateDistanceValueByUnit, recalculateDistanceValueToKm } from '@/shared/google/helpers';
import DepartmentSelection from '@/components/DepartmentsSelection';
import { getUsers } from '@/cruds/users.crud';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import TransportTypeSelector from '../../modules/transports/components/TransportTypeSelector.vue';
import _ from 'lodash';

const defaultMileage = {
	km: 0,
	fromLocation: '',
	toLocation: '',
	description: '',
	kgCoTwo: 0,
	subCategory: 6,
	department: 0,
	category: 0,
	subType: 0,
	additionalSubType: 0
};

export default {
	name: 'CreateNewExpenseMileageComponent',
	components: {
		TransportTypeSelector,
		DateDescription,
		GoogleDistance,
		Info,
		DepartmentSelection
	},
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		formType: {
			type: Number,
			required: false,
			default: 2
		},
		isSubmitted: {
			type: Boolean,
			required: true,
			default: false
		},
		isNew: {
			type: Boolean
		},
		transportTypeFromVehicule: {
			type: Object,
			required: false,
			default: () => ({})
		}
	},
	data() {
		return {
			mileage: Object.assign({}, defaultMileage),
			dontRecalculate: false,
			incRecalculate: 0,
			popInfoDistance: false,
			user: {
				value: 0,
				name: ''
			},
			disableSelectUser: false,
			users: [{ value: 0, name: 'Select ...' }],
			allowExpenseEntryOutsideContract: 0,
			messageAllowExpenseEntryOutsideContract: '',
			dateSelected: moment(Date.now()).format('YYYY-MM-DD') + 'T00:00:00Z',
			showPopUp: false,
			showAlert: false,

			departmentSelectionValid: false
		};
	},
	computed: {
		optionsForTransportMean() {
			let result = this.FormMenu(905); // 1124 is without planes

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		setAllowExpenseEntryOutsideContract() {
			return store.state.myProject.allowExpenseEntryOutsideContract;
		}
	},
	watch: {
		mileage: {
			handler(mileage) {
				//    console.log("in handler mileage",mileage);
				this.$v.$touch();
				this.isInvalidateFields(this.departmentSelectionValid);
				this.$emit(
					'change',
					{
						...mileage,
						km: parseFloat(mileage.km),
						subCategory: parseInt(mileage.subCategory),
						subType: parseInt(mileage.subType),
						additionalSubType: parseInt(mileage.additionalSubType)
					},
					this.$v.$invalid
				);
			},
			deep: true
		},
		transportTypeFromVehicule: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.mileage.subCategory = newVal.subCategory;
					this.mileage.subType = newVal.subType;
					this.mileage.additionalSubType = newVal.additionalSubType;
				}
			}
		}
	},
	created() {
		this.getAllUsers();
		this.setUser();
		this.handleSelectUser(this.user.value);
		if (!isNil(this.editData)) {
			this.mileage = {
				...this.mileage,
				...objReducer(
					[
						'description',
						'originalKm',
						'km',
						'originalFromLocation',
						'fromLocation',
						'originalToLocation',
						'toLocation',
						'kgCoTwo',
						'subCategory',
						'subType',
						'additionalSubType'
					],
					this.editData
				),
				description: this.editData.description
			};
		}
	},
	methods: {
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.mileage.subCategory = type;
			this.mileage.subType = detail === null ? 0 : detail;
			this.mileage.additionalSubType = additionalDetail === null ? 0 : additionalDetail;
		},
		handleSelectUser(user) {
			this.$emit('changeUser', user);
		},
		setUser() {
			if (this.editData) {
				this.disableSelectUser = true;
				this.user.value = parseInt(this.editData.user.id, 10);
			} else {
				this.user.value = store.state.myProfile.id;
			}
		},
		async getAllUsers() {
			let forTimeSheet = true;
			this.users = [
				...this.users,
				...[...(await getUsers({ myProject: true }, 0, -1, 0, 0, 0, '', '', forTimeSheet))].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];
			if (this.useComponent) {
				this.users = this.users.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			let isResult = this.users.find((item) => +item.value === +this.user.value);
			if (isResult === undefined) {
				this.user.value = 0;
				this.$emit('changeUser', this.user.value);
			} else {
				this.$emit('changeUser', this.user.value);
			}
		},
		handleDepartmentAndCatChange(payload) {
			this.mileage.department = payload.department;
			this.mileage.category = payload.category;
		},
		isInvalidateFields(payload) {
			const checkInvalidTransport = this.$refs.transportTypeRef.isInvalid();

			this.departmentSelectionValid = payload;
			this.$emit('mileage:invalid', payload === true || checkInvalidTransport === true);
		},
		handleDateAndDescriptionChanges(obj) {
			this.mileage = {
				...this.mileage,
				...objReducer(['date'], obj)
			};
			const _o = objReducer(['date'], obj);
			this.dateSelected = _o.date;
		},
		/**
		 * @param {Object} options
		 */
		async updateKgCO() {
			if (recalculateDistanceValueToKm(this.mileage.km) > 0) {
				const transportMeanMapped = this.mappedTransportTypeToCarbonClapValue(
					this.mileage.subCategory,
					this.mileage.subCategory,
					this.mileage.additionalSubType
				);
				this.mileage.kgCoTwo = await GetKmGreenImpact(recalculateDistanceValueToKm(this.mileage.km), transportMeanMapped);
			} else {
				this.mileage.kgCoTwo = 0;
			}
		},
		handleCategoryChange() {
			this.updateKgCO();
		},
		handleDistanceChanges(options) {
			this.mileage = {
				...this.mileage,
				...objReducer(['fromLocation', 'toLocation', 'km'], options)
			};

			this.setOriginalKm();
			this.updateKgCO();
		},
		setOriginalKm() {
			this.incRecalculate++;
			if (
				!this.dontRecalculate &&
				this.incRecalculate === 2 &&
				!isNil(this.mileage.originalKm) &&
				this.mileage.originalKm > 0 &&
				this.mileage.originalKm !== this.mileage.km /*&&
                (this.mileage.originalFromLocation === this.mileage.fromLocation && this.mileage.originalToLocation === this.mileage.toLocation) // ||
                // (this.mileage.originalFromLocation === this.mileage.toLocation && this.mileage.originalToLocation === this.mileage.fromLocation) */
			) {
				this.mileage.km = recalculateDistanceValueByUnit(this.mileage.originalKm);
				this.dontRecalculate = true;
			}
		},
		openInfoDistance() {
			this.popInfoDistance = !this.popInfoDistance;
		}
	},
	validations() {
		return {
			mileage: {
				description: {
					required,
					min: minLength(3)
				},
				km: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	}
};
</script>
<style lang="scss">
.info-message {
	display: inline-flex;
	letter-spacing: 0.01em;
	font-weight: 500;
	color: #225cbd;
	font-size: 13px;
	margin-top: 4px;
	cursor: pointer;

	.label {
		margin-left: 4px;
		margin-top: 1px;
	}
}
</style>
